
import { defineComponent } from "vue";

import { useNewspaperPage } from "@/composable/useNewspaperPage";

import MApp from "@/components/ui/mobile/app/MApp.vue";
import MAppBarCompact from "@/components/ui/mobile/app-bar/MAppBarCompact.vue";
import MBottomNavigation from "@/components/ui/mobile/bottom-navigation/MNavigation.vue";
import MKidList from "@/components/mobile/MKidList.vue";
import PNewspaper from "@/components/PNewspaper.vue";

export default defineComponent({
  name: "MHome",
  components: {
    MApp,
    MKidList,
    MAppBarCompact,
    PNewspaper,
    MBottomNavigation
  },

  setup() {
    useNewspaperPage();
  }
});
