
import { computed, defineComponent, PropType } from "vue";
import { RouteRecordRaw, useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "MNavigationLink",
  props: {
    to: { type: Object as PropType<Partial<RouteRecordRaw>>, required: true },
    icon: { type: String },
    exact: { type: Boolean }
  },

  setup(props) {
    const router = useRouter();
    const route = useRoute();

    const current = computed(() => {
      return route.name === props.to.name;
    });

    const push = () => {
      if (props.to.name !== route.name) return router.push(props.to);
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return { props, route, push, current };
  }
});
