import { computed, watch } from "vue";
import { useRoute } from "vue-router";

import { useNews } from "@/store/news.store";

export function useNewspaperPage() {
  const route = useRoute();

  const userId = computed(() => route.params.id?.toString());

  const news = useNews();

  function loadNews(page?: number, size?: number) {
    return news.loadNews(userId.value, page, size);
  }

  watch(
    () => route.params.id,
    () => loadNews(0, 10),
    { immediate: true }
  );
}
