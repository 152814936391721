
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import { isEqual } from "lodash";

import { useKids } from "@/store/kids.store";

import MTabs from "@/components/ui/mobile/tabs/MTabs.vue";
import MTabItem from "@/components/ui/mobile/tabs/MTabItem.vue";

export default defineComponent({
  name: "MKidList",
  components: { MTabItem, MTabs },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const kids = useKids();

    const isCurrent = (id: number) => isEqual(id.toString(), route.params.id);

    const select = (id: number) => {
      router.replace({ params: { id } });
    };

    return { select, isCurrent, kids: kids.kids };
  }
});
