
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "MNavigationProfile",
  props: {
    to: { type: Object, required: true },
    icon: { type: String },
    exact: { type: Boolean }
  },

  setup(props) {
    const route = useRoute();
    const firstLetter = computed(() => {
      // return 0;
      return JSON.parse(sessionStorage.getItem("SESSION") || "{name: '0'}")
        .name[0];
    });

    return { props, firstLetter, route };
  }
});
