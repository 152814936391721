
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import MIconButton from "@/components/ui/mobile/buttons/MIconButton.vue";

export default defineComponent({
  name: "MAppBarCompact",
  components: { MIconButton },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const shadow = ref(false);

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    const handleScroll = () => {
      if (window.scrollY >= 1 && !shadow.value) {
        shadow.value = true;
      }

      if (window.scrollY < 1 && shadow.value) {
        shadow.value = false;
      }
    };

    return { route, router, shadow };
  }
});
